<template>
  <v-container fluid>
    <v-dialog v-model="dialogForm.show" persistent :overlay="false" max-width="600px">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-card-title class="title primary white--text mb-4">Event</v-card-title>
          <v-card-text>
            <p>
              Please provide the appropriate details below.
            </p>

            <div class="font-weight-bold mb-3">Event</div>
            <v-text-field
              label="Event Title"
              v-model="dialogForm.event.title"
              type="text"
              filled
              :rules="[dialogForm.rules.required]"
              required
            ></v-text-field>

            <v-select
              v-model="dialogForm.event.customerId"
              :items="customers"
              label="Customer"
              item-text="name"
              item-value="id"
              filled
            ></v-select>

            <v-textarea
              v-model="dialogForm.event.notes"
              label="Notes"
              filled
            ></v-textarea>

            <div class="font-weight-bold mb-3">Date / Location</div>
            <v-menu
              ref="menu"
              v-model="dialogForm.menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dialogForm.event.day"
                  label="Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  filled
                  :rules="[dialogForm.rules.required]"
                  required
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dialogForm.event.day"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="$refs.menu.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>

            <v-text-field
              label="Start Time"
              v-model="dialogForm.event.startTime"
              type="time"
              filled
              :rules="[dialogForm.rules.required]"
              required
            ></v-text-field>

            <v-text-field
              label="End Time"
              v-model="dialogForm.event.endTime"
              type="time"
              filled
              :rules="[dialogForm.rules.required]"
              required
            ></v-text-field>

            <v-text-field
              label="Location"
              v-model="dialogForm.event.location"
              type="text"
              filled
              :rules="[dialogForm.rules.required]"
              required
            ></v-text-field>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="cancelDialogForm"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              @click="saveEvent"
              :loading="dialogForm.saving"
              >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-row wrap>
      <v-col cols="12" xs="12">
        <v-card class="elevation-0">
          <v-card-title
            class="pa-3 primary white--text font-weight-black headline"
          >
            <span class="ml-4">Calendar</span>
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-container fluid>
              <v-row class="fill-height">
                <v-col>
                  <v-sheet height="64">
                    <v-toolbar
                      flat
                    >
                      <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        @click="setToday"
                      >
                        Today
                      </v-btn>
                      <v-select
                        v-model="type"
                        :items="types"
                        dense
                        outlined
                        hide-details
                        class="ma-2"
                        style="max-width: 200px;"
                        label="Calendar"
                      ></v-select>
                      <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="prev"
                      >
                        <v-icon small>
                          mdi-chevron-left
                        </v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="next"
                      >
                        <v-icon small>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                      <v-toolbar-title v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary mr-2"
                        @click="dialogForm.show = true"
                      >
                        <span>Add Event</span>
                      </v-btn>
                    </v-toolbar>
                  </v-sheet>
                  <v-sheet height="600">
                    <v-calendar
                      ref="calendar"
                      v-model="focus"
                      color="primary"
                      :events="filteredEvents"
                      :type="type"
                      :loading="loading"
                      @click:event="showEvent"
                      @click:more="viewDay"
                      @click:date="viewDay"
                    >
                      <template #event="event">
                        &nbsp;{{ event.eventParsed.input.title }}
                      </template>
                    </v-calendar>
                  </v-sheet>
                  <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                  >
                    <v-card
                      min-width="350px"
                      flat
                    >
                      <v-toolbar
                        color="primary"
                        dark
                      >
                        <v-toolbar-title>Event</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn @click="selectedOpen = false" icon>
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-card-text v-if="selectedEvent">
                        <div>
                          <strong>Title: </strong> {{selectedEvent.title}}<br/>
                          <strong>Customer: </strong> <a :href="`/customer/profile/${selectedEvent.customerId}`">{{selectedEvent.customer}}</a><br/>
                          <strong>Notes: </strong> {{selectedEvent.notes}}<br/>
                          <strong>Start: </strong> {{this.formatDate(selectedEvent.start)}}<br/>
                          <strong>End: </strong> {{this.formatDate(selectedEvent.end)}}<br/>
                          <strong>Location: </strong> {{selectedEvent.location}}<br/>
                          <br/>
                          <v-btn
                            color="primary"
                            text
                            outlined
                            @click="addToOutlookCalendar(selectedEvent, 'outlook')"
                            x-small
                            class="ml-1 mb-1"
                            style="min-width: 140px;"
                          >
                            + Add to Outlook
                            <v-icon dark x-small class="ml-1">
                              calendar_month
                            </v-icon>
                          </v-btn>
                          <br/>
                          <v-btn
                            color="primary"
                            text
                            outlined
                            @click="addToOutlookCalendar(selectedEvent, 'google')"
                            x-small
                            class="ml-1 mb-1"
                            style="min-width: 140px;"
                          >
                            + Add to Google
                            <v-icon dark x-small class="ml-1">
                              calendar_month
                            </v-icon>
                          </v-btn>
                          <br/>
                          <v-btn
                            color="primary"
                            text
                            outlined
                            @click="addToOutlookCalendar(selectedEvent, 'yahoo')"
                            x-small
                            class="ml-1 mb-1"
                            style="min-width: 140px;"
                          >
                            + Add to Yahoo
                            <v-icon dark x-small class="ml-1">
                              calendar_month
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          outlined
                          color="primary"
                          @click="editEvent"
                        >
                          Edit
                        </v-btn>
                        <v-btn
                          text
                          outlined
                          color="red"
                          :disabled="saving"
                          :loading="saving"
                          @click="deleteEvent"
                        >
                          Delete
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
 import fb from "@/main";
 import moment from "moment";
 import { mapGetters } from "vuex";
 import { office365, google, yahoo } from "calendar-link";

 export default {
    components: {
    },
    data: () => ({
      today: '',
      focus: '',
      type: 'month',
      types: ['month', 'week', 'day'],
      selectedEvent: null,
      selectedElement: null,
      selectedOpen: false,
      date: '',
      loading: false,
      saving: false,
      dialogForm: {
        show: false,
        menu: false,
        rules: {
          required: value => !!value || 'Required.'
        },
        event: {}
      },
      snack: false,
      snackColor: "",
      snackText: "",
      events: [],
      customers: [],
      filteredEvents: [],
    }),
    mounted () {
      this.getCustomers();
      this.getCalendarEvents();
      this.$refs.calendar.move(0);
    },
    computed: {
      ...mapGetters(["user"]),
    },
    methods: {
      setToday () {
        this.focus = ''
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      filterEvents() {
        this.filteredEvents = this.events;
      },
      async getCalendarEvents() {
        try {
          this.loading = true;
          const getEvents = fb.db.collection("events");
          const querySnapshot = await getEvents.get();
          const data = querySnapshot.docs.map(doc => {return {id: doc.id, ...doc.data()}});
          const events = data.map((d) => {return this.formatEvent(d)});
          this.events = events;
          this.filterEvents();
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      },
      cancelDialogForm() {
        this.dialogForm.show = false;
        this.dialogForm.event = {};
      },
      formatDate (date) {
        if (date && date.seconds) {
          return moment(new Date(date.seconds * 1000)).format("MM/DD/YYYY hh:mm A");
        } else {
          return moment(date).format("MM/DD/YYYY hh:mm A");
        }
      },
      formatEvent (event) {
        event.day = moment(new Date(event.start.seconds * 1000)).format("YYYY-MM-DD");
        event.start = new Date(event.start.seconds * 1000);
        event.end = new Date(event.end.seconds * 1000);
        event.startTime = moment(event.start).format('HH:mm:ss.000'); // for edit dialog
        event.endTime = moment(event.end).format('HH:mm:ss.000'); // for edit dialog
        event.timed = true; // so calendar shows timespan view
        event.customer = (this.customers.find((c) => c.id == event.customerId) || {}).name || '';
        return event;
      },
      async deleteEvent () {
        if (confirm("Are you sure?") == true) {
          this.saving = true;
          await fb.db.collection("events").doc(this.selectedEvent.id).delete();
          this.getCalendarEvents();
          this.selectedOpen = false;
          this.saving = false;
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Successfully deleted event.";
        }
      },
      async saveEvent () {
        const formValid = this.$refs.form.validate();
        if (!formValid) {
          alert("Please fill out the required fields.")
          return;
        }

        const obj = {};
        Object.keys(this.dialogForm.event).forEach((key) => {
          if (!['day', 'startTime', 'endTime', 'timed', 'id', 'customer'].includes(key)) {
            obj[key] = this.dialogForm.event[key];
          }
        });
        obj.start = new Date(`${this.dialogForm.event.day} ${this.dialogForm.event.startTime}`);
        obj.end = new Date(`${this.dialogForm.event.day} ${this.dialogForm.event.endTime}`);

        if (obj.end <= obj.start) {
          alert("Please provide an end time that comes after start time.")
          return
        }

        this.saving = true;
        if (this.dialogForm.event.id) {
          await fb.db.collection("events").doc(this.dialogForm.event.id).update(obj);
        } else {
          obj.createDate = new Date();
          await fb.db.collection("events").add(obj);
        }

        this.getCalendarEvents();
        this.saving = false;
        this.dialogForm.show = false;
        this.dialogForm.event = {};
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully saved event.";
      },
      editEvent() {
        this.dialogForm.event = this.selectedEvent;
        this.dialogForm.show = true;
      },
      showDialogCopy() {
        this.dialogCopy.event = JSON.parse(JSON.stringify(this.selectedEvent));
        this.dialogCopy.show = true;
      },
      async getCustomers() {
        const getCustomers = fb.db.collection("customers");
        const querySnapshot = await getCustomers.get();
        const data = querySnapshot.docs.map(doc => {
          const d = doc.data();
          const name = d.company ? `${d.firstName} ${d.lastName} @ ${d.company}` : `${d.firstName} ${d.lastName}`;
          return {id: doc.id, name}
        });
        this.customers = data;
      },
      getCalendarLinks(event) {
        const start = new Date(this.formatDate(event.start));
        const end = new Date(this.formatDate(event.end));
        const data = {
          title: event.customer ? `${event.title} - ${event.customer}` : event.title,
          location: event.location,
          start: start,
          end: end
        };
        return {office365: office365(data), google: google(data), yahoo: yahoo(data)};
      },
      addToOutlookCalendar(event, platform) {
        const calenderLinks = this.getCalendarLinks(event)
        console.log()
        if (platform === 'outlook') {
          window.open(calenderLinks.office365, '_blank');
        }
        if (platform === 'google') {
          window.open(calenderLinks.google, '_blank');
        }
        if (platform === 'yahoo') {
          window.open(calenderLinks.yahoo, '_blank');
        }
      }
    },
  }
</script>
