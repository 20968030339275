<template>
  <v-dialog v-model="show" persistent :overlay="false" max-width="600px">
    <v-card>
      <v-form ref="form" lazy-validation>
        <v-card-title class="title primary white--text mb-4">{{project.name}}</v-card-title>
        <v-card-text>

          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="px-0 pl-1 mt-8 grey lighten-3 py-1" colspan="2">
                    <strong>Customer</strong>
                  </td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Name</td>
                  <td class="px-0 pr-1">{{ project.customer.firstName }} {{ project.customer.lastName }}</td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Company</td>
                  <td class="px-0 pr-1">{{ project.customer.company }}</td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Email</td>
                  <td class="px-0 pr-1">{{ project.customer.email }}</td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Phone</td>
                  <td class="px-0 pr-1">{{ project.customer.phone }}</td>
                </tr>
                <tr>
                  <td class="px-0 pl-1 mt-8 grey lighten-3 py-1" colspan="2">
                    <strong>Project Details</strong>
                  </td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Address</td>
                  <td class="px-0 pr-1">{{ project.useCustomerAddress ? formatAddress(project.customer.address) : formatAddress(project.address) }}</td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Zones</td>
                  <td class="px-0 pr-1">{{ project.zones }}</td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Sq. Ft.</td>
                  <td class="px-0 pr-1">{{ project.squareFootage }}</td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Cost</td>
                  <td class="px-0 pr-1">{{ project.cost }}</td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Quote</td>
                  <td class="px-0 pr-1">{{ project.quote }}</td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Notes</td>
                  <td class="px-0 pr-1">{{ project.notes }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click.stop="show=false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatAddress } from "@/helpers";

export default {
  name: "ProductViewDialog",
  props: ["project", "visible"],
  data() {
    return {
    };
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    formatAddress: formatAddress
  }
}
</script>