<template>
  <v-container grid-list-xl>
    <v-row>
      <v-col lg="4" cols="sm" class="pb-2">
          <v-card>
              <v-row class="no-gutters">
                  <div class="col-auto">
                      <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                      <h5 class="text-truncate text-uppercase">Leads</h5>
                      <h1>{{leads || '...'}}</h1>
                  </div>
              </v-row>
          </v-card>
      </v-col>
      <v-col lg="4" cols="sm" class="pb-2">
          <v-card>
              <v-row class="no-gutters">
                  <div class="col-auto">
                      <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                      <h5 class="text-truncate text-uppercase">Customers</h5>
                      <h1>{{customers || '...'}}</h1>
                  </div>
              </v-row>
          </v-card>
      </v-col>
      <v-col lg="4" cols="sm" class="pb-2">
          <v-card>
              <v-row class="no-gutters">
                  <div class="col-auto">
                      <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                      <h5 class="text-truncate text-uppercase">Projects</h5>
                      <h1>{{projects || '...'}}</h1>
                  </div>
              </v-row>
          </v-card>
      </v-col>
      <v-col lg="4" cols="sm" class="pb-2">
          <v-card>
              <v-row class="no-gutters">
                  <div class="col-auto">
                      <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                      <h5 class="text-truncate text-uppercase">Cost</h5>
                      <h1>${{cost || '...'}}</h1>
                  </div>
              </v-row>
          </v-card>
      </v-col>
      <v-col lg="4" cols="sm" class="pb-2">
          <v-card>
              <v-row class="no-gutters">
                  <div class="col-auto">
                      <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                      <h5 class="text-truncate text-uppercase">Revenue</h5>
                      <h1>${{revenue || '...'}}</h1>
                  </div>
              </v-row>
          </v-card>
      </v-col>
      <v-col lg="4" cols="sm" class="pb-2">
          <v-card>
              <v-row class="no-gutters">
                  <div class="col-auto">
                      <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                      <h5 class="text-truncate text-uppercase">Profit</h5>
                      <h1>${{profit || '...'}}</h1>
                  </div>
              </v-row>
          </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fb from "@/main";
import { mapGetters } from "vuex";

export default {
  components: {
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => {
    return {
      loading: false,
      customers: 0,
      leads: 0,
      projects: 0,
      revenue: 0,
      profit: 0,
      cost: 0,
    }
  },
  async created() {
    this.loading = true;
    await this.getCustomers();
    await this.getProjects();
    this.loading = false;
  },
  mounted: async function() {
  },
  methods: {
    async getCustomers() {
      const customersRes = await fb.db
        .collection("customers")
        .where("createdBy.uid", "==", this.user.uid)
        .get();
      const customers = [];
      customersRes.forEach((q) => {
        customers.push({
          ...q.data(),
          id: q.id,
        });
      });
      this.leads = customers.filter((c)=> c.status === 'Lead').length;
      this.customers = customers.filter((c)=> c.status === 'Customer').length;
    },
    async getProjects() {
      const projectsRes = await fb.db
        .collection("projects")
        .where("createdBy.uid", "==", this.user.uid)
        .get();
      const projects = [];
      let revenue = 0;
      let cost = 0;
      projectsRes.forEach((q) => {
        const data = {
          ...q.data(),
          id: q.id,
        };
        revenue += (data.quote*1) || 0;
        cost += (data.cost*1) || 0;
        projects.push(data);
      });
      this.revenue = `${(revenue * 1).toFixed(2)}`;
      this.cost = `${(cost * 1).toFixed(2)}`;
      this.profit = `${(revenue - cost).toFixed(2)}`;
      this.projects = `${projects.length}`;
    },
  }
};
</script>