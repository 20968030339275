<template>
  <v-container grid-list-lg>
    <v-layout wrap>
      <v-col xs12>
        <v-skeleton-loader type="card" v-if="loading"></v-skeleton-loader>
        <v-card v-if="!loading">
          <v-card-title primary-title>Profile</v-card-title>
          <v-card-text>
            <p><strong>Name:</strong> {{this.user.firstName}} {{this.user.lastName}}</p>
            <p><strong>Phone:</strong> {{this.user.phone}}</p>
            <p><strong>Company:</strong> {{this.user.company}}</p>
            <p><strong>Zip Code:</strong> {{this.user.zip}}</p>
            <p><strong>Account:</strong>
              <v-chip
                :color="'success'"
                class="text-capitalize ml-2"
                small
              >
                FREE
              </v-chip>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-layout>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
// import fb from "@/main";

export default {
  data() {
    return {
      loading: true,
      snack: false,
      snackColor: "",
      snackText: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    openDialog() {
      this.dialog = true;
    }
  },
  mounted: async function () {
    this.loading = false;
    console.log('test', this.user)
  },
};
</script>
