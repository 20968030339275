<template>
  <v-dialog v-model="show" persistent :overlay="false" max-width="600px">
    <v-card>
      <v-form ref="form" lazy-validation>
        <v-card-title class="title primary white--text mb-4">{{ customer.firstName }} {{ customer.lastName }}</v-card-title>
        <v-card-text>

          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="px-0 pl-1 mt-8 grey lighten-3 py-1" colspan="2">
                    <strong>Details</strong>
                  </td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Status</td>
                  <td class="px-0 pr-1">
                    <v-chip
                      :color="customer.status === 'Lead' ? 'amber' : 'success'"
                      class="text-capitalize"
                      small
                    >
                      {{customer.status}}
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Company</td>
                  <td class="px-0 pr-1">{{ customer.company }}</td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Email</td>
                  <td class="px-0 pr-1">{{ customer.email }}</td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Phone</td>
                  <td class="px-0 pr-1">{{ customer.phone }}</td>
                </tr>
                <tr>
                  <td class="px-0 pr-1 pl-1" width="120">Address</td>
                  <td class="px-0 pr-1">{{ formatAddress(customer.address) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click.stop="show=false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatAddress } from "@/helpers";

export default {
  name: "CustomerViewDialog",
  props: ["customer", "visible"],
  data() {
    return {
    };
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    formatAddress: formatAddress
  }
}
</script>