import Vue from "vue";
import Vuex from "vuex";
import router from  "@/router"
import fb from "@/main"
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        user: null,
    },
    getters: {
        user: (state) => {
            return state.user;
          },
    },
    mutations: {
        cacheUser(state, user) {
            state.user = user;
          },
    },
    actions: {
        signout: async function () {
            try {
              await fb.auth.signOut();
              router.push("/login");
            } catch (error) {
              alert(error);
            }
          },
          getUser: async function ({ commit }) {
            try {
              const { uid } = await fb.auth.currentUser;
              const userRes = await fb.db.collection('users').doc(uid).get()
              let user = userRes.data()
              commit("cacheUser", user)
            } catch (error) {
              alert(error);
            }
          },
    }
})

export default store;