<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        v-if="privateRoute()"
        dark
      >
        <v-list dense>
          <template v-for="item in navItems">
            <v-list-item :key="item.text" link :to="item.to">
              <v-list-item-action>
                <v-icon small>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-divider></v-divider>
          <v-list-item link @click="signout">
            <v-list-item-action>
              <v-icon small>exit_to_app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Log out
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="white"
        v-if="privateRoute()"
      >
        <v-toolbar-title class="ml-0">
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </v-toolbar-title>
      <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 1218.84 327.18"
                          style="enable-background: new 0 0 1218.84 327.18"
                          xml:space="preserve"
                        >
                          <g>
                            <path
                              d="M281.49,55.95h25.31v42.82h49.15V55.95h25.31v114.85h-25.31v-49.64H306.8v49.64h-25.31V55.95z"
                            />
                            <path
                              d="M393.25,55.95h28.87l28.39,75.27h0.33l28.87-75.27h27.09l-47.37,114.85h-20.28L393.25,55.95z"
                            />
                            <path
                              d="M547.68,55.95h20.93l49.96,114.85h-28.55l-9.89-24.33h-44.61l-9.57,24.33h-27.9L547.68,55.95z M557.41,89.36l-13.95,35.69
		h28.06L557.41,89.36z"
                            />
                            <path
                              d="M705.19,85.79c-2.49-3.03-5.54-5.35-9.17-6.97c-3.62-1.62-7.81-2.43-12.57-2.43c-4.76,0-9.17,0.92-13.22,2.76
		c-4.06,1.84-7.57,4.41-10.54,7.71c-2.98,3.3-5.3,7.22-6.98,11.76c-1.68,4.54-2.51,9.46-2.51,14.76c0,5.41,0.84,10.36,2.51,14.84
		c1.68,4.49,3.98,8.38,6.89,11.68c2.92,3.3,6.35,5.87,10.3,7.7c3.95,1.84,8.19,2.76,12.73,2.76c5.19,0,9.79-1.08,13.79-3.24
		c4-2.16,7.3-5.19,9.9-9.08l21.09,15.73c-4.87,6.81-11.03,11.84-18.49,15.09c-7.46,3.24-15.14,4.87-23.03,4.87
		c-8.98,0-17.25-1.41-24.82-4.22c-7.57-2.81-14.11-6.84-19.63-12.08c-5.52-5.24-9.81-11.6-12.9-19.06
		c-3.08-7.46-4.62-15.79-4.62-24.98c0-9.19,1.54-17.52,4.62-24.98c3.08-7.46,7.38-13.81,12.9-19.06
		c5.51-5.24,12.06-9.27,19.63-12.08c7.57-2.81,15.84-4.22,24.82-4.22c3.24,0,6.62,0.3,10.14,0.89c3.51,0.6,6.97,1.54,10.38,2.84
		c3.41,1.3,6.68,3.03,9.81,5.19c3.13,2.16,5.95,4.81,8.43,7.95L705.19,85.79z"
                            />
                          </g>
                          <g>
                            <path
                              class="st0"
                              d="M889.54,211.5h34.79c4.85,0,9.43,0.44,13.76,1.32c4.32,0.88,8.09,2.4,11.31,4.56
		c3.22,2.16,5.77,5.07,7.67,8.73c1.9,3.66,2.84,8.27,2.84,13.82c0,5.47-0.88,10.05-2.65,13.76c-1.77,3.7-4.19,6.66-7.28,8.86
		c-3.09,2.2-6.75,3.77-10.98,4.69c-4.23,0.93-8.82,1.39-13.76,1.39h-15.08v36.51h-20.63V211.5z M910.17,251.19h13.76
		c1.85,0,3.64-0.18,5.36-0.53c1.72-0.35,3.26-0.95,4.63-1.79c1.37-0.84,2.47-1.99,3.31-3.44c0.84-1.45,1.26-3.28,1.26-5.49
		c0-2.38-0.55-4.3-1.65-5.75c-1.1-1.45-2.51-2.58-4.23-3.37c-1.72-0.79-3.64-1.3-5.75-1.52c-2.12-0.22-4.15-0.33-6.08-0.33h-10.58
		V251.19z"
                            />
                            <path
                              class="st0"
                              d="M972.87,211.5h36.24c4.76,0,9.28,0.46,13.56,1.39c4.28,0.93,8.02,2.47,11.24,4.63
		c3.22,2.16,5.77,5.07,7.67,8.73c1.9,3.66,2.84,8.22,2.84,13.69c0,6.61-1.72,12.24-5.16,16.87c-3.44,4.63-8.47,7.56-15.08,8.8
		l23.81,39.55h-24.74l-19.58-37.43H993.5v37.43h-20.63V211.5z M993.5,250.26h12.17c1.85,0,3.81-0.07,5.89-0.2
		c2.07-0.13,3.95-0.53,5.62-1.19c1.67-0.66,3.06-1.7,4.17-3.11c1.1-1.41,1.65-3.39,1.65-5.95c0-2.38-0.49-4.28-1.45-5.69
		c-0.97-1.41-2.21-2.49-3.7-3.24c-1.5-0.75-3.22-1.26-5.16-1.52c-1.94-0.27-3.84-0.4-5.69-0.4H993.5V250.26z"
                            />
                            <path
                              class="st0"
                              d="M1053.55,258.33c0-7.49,1.26-14.29,3.77-20.37c2.51-6.08,6.02-11.26,10.52-15.54c4.5-4.28,9.83-7.56,16-9.86
		c6.17-2.29,12.92-3.44,20.24-3.44c7.32,0,14.06,1.15,20.24,3.44c6.17,2.29,11.51,5.58,16,9.86c4.5,4.28,8,9.46,10.52,15.54
		c2.51,6.08,3.77,12.88,3.77,20.37c0,7.5-1.26,14.28-3.77,20.37c-2.51,6.08-6.02,11.27-10.52,15.54c-4.5,4.28-9.83,7.56-16,9.85
		c-6.17,2.29-12.92,3.44-20.24,3.44c-7.32,0-14.07-1.15-20.24-3.44c-6.17-2.29-11.51-5.58-16-9.85c-4.5-4.28-8-9.46-10.52-15.54
		C1054.81,272.61,1053.55,265.83,1053.55,258.33z M1074.98,258.33c0,4.41,0.68,8.44,2.05,12.1c1.37,3.66,3.33,6.83,5.89,9.52
		c2.56,2.69,5.62,4.78,9.19,6.28c3.57,1.5,7.56,2.25,11.97,2.25c4.41,0,8.4-0.75,11.97-2.25c3.57-1.5,6.63-3.59,9.19-6.28
		c2.56-2.69,4.52-5.86,5.89-9.52c1.37-3.66,2.05-7.69,2.05-12.1c0-4.32-0.68-8.33-2.05-12.04c-1.37-3.7-3.33-6.9-5.89-9.59
		c-2.56-2.69-5.62-4.78-9.19-6.28c-3.57-1.5-7.56-2.25-11.97-2.25c-4.41,0-8.4,0.75-11.97,2.25c-3.57,1.5-6.64,3.6-9.19,6.28
		c-2.56,2.69-4.52,5.89-5.89,9.59C1075.67,250,1074.98,254.01,1074.98,258.33z"
                            />
                          </g>
                          <g>
                            <path
                              d="M281.44,214.05h12.73l33.18,76.39l33.18-76.39h12.09v91.05h-8.49v-80.24h-0.26l-34.72,80.24h-3.73l-35.23-80.24h-0.26
		v80.24h-8.49V214.05z"
                            />
                            <path
                              d="M418.13,214.05h8.74l38.32,91.05h-9.52l-9.9-24.18h-48.22l-10.29,24.18h-8.87L418.13,214.05z M422.12,223.31l-21.22,49.9
		h41.79L422.12,223.31z"
                            />
                            <path
                              d="M471.89,214.05h10.93l55.42,79.73h0.26v-79.73h8.49v91.05h-10.93l-55.42-79.73h-0.26v79.73h-8.49V214.05z"
                            />
                            <path
                              d="M593.41,214.05h8.74l38.32,91.05h-9.52l-9.9-24.18h-48.22l-10.29,24.18h-8.87L593.41,214.05z M597.39,223.31l-21.22,49.9
		h41.79L597.39,223.31z"
                            />
                            <path
                              d="M722.26,299.18c-5.32,3-10.82,5.12-16.53,6.37c-5.7,1.24-11.51,1.86-17.42,1.86c-6.94,0-13.31-1.18-19.1-3.54
		s-10.74-5.66-14.85-9.9c-4.12-4.24-7.33-9.3-9.64-15.17c-2.32-5.87-3.47-12.28-3.47-19.22c0-6.95,1.16-13.33,3.47-19.16
		c2.31-5.83,5.53-10.87,9.64-15.11c4.11-4.24,9.07-7.56,14.85-9.97c5.79-2.4,12.15-3.6,19.1-3.6c13.54,0,24.47,4.2,32.79,12.6
		l-6.17,6.43c-1.37-1.63-3.07-3.15-5.08-4.56c-2.02-1.41-4.2-2.61-6.56-3.6c-2.36-0.98-4.82-1.76-7.39-2.31
		c-2.57-0.56-5.1-0.84-7.59-0.84c-5.92,0-11.25,1.03-16.01,3.09c-4.76,2.06-8.81,4.89-12.15,8.49c-3.34,3.6-5.91,7.84-7.72,12.73
		s-2.7,10.16-2.7,15.82c0,5.66,0.9,10.93,2.7,15.82c1.8,4.89,4.37,9.13,7.72,12.73c3.34,3.6,7.39,6.43,12.15,8.49
		c4.76,2.06,10.09,3.09,16.01,3.09c4.89,0,9.47-0.39,13.76-1.16c4.29-0.77,8.19-2.19,11.7-4.24v-30.61h-21.86v-7.72h30.35V299.18z"
                            />
                            <path
                              d="M740.78,214.05h55.68v7.72h-47.19v32.41h44.24v7.72h-44.24v35.49h49.25v7.72h-57.74V214.05z"
                            />
                            <path
                              d="M810.6,214.05h23.92c1.2,0,2.83,0.04,4.89,0.13c2.06,0.09,4.29,0.34,6.69,0.77c2.4,0.43,4.84,1.12,7.33,2.06
		c2.49,0.94,4.71,2.31,6.69,4.12c1.97,1.8,3.6,4.07,4.89,6.82c1.29,2.74,1.93,6.09,1.93,10.03c0,4.37-0.75,7.97-2.25,10.8
		c-1.5,2.83-3.39,5.12-5.66,6.88c-2.27,1.76-4.78,3.07-7.52,3.92c-2.74,0.86-5.36,1.46-7.85,1.8l26.49,43.72h-9.64l-25.59-43.21
		h-15.82v43.21h-8.49V214.05z M819.09,254.17h18c4.46,0,8.08-0.56,10.87-1.67c2.78-1.11,4.95-2.49,6.49-4.12
		c1.54-1.63,2.59-3.39,3.15-5.27c0.56-1.88,0.84-3.6,0.84-5.14s-0.28-3.26-0.84-5.14c-0.56-1.88-1.61-3.64-3.15-5.27
		c-1.54-1.63-3.71-3-6.49-4.12c-2.79-1.11-6.41-1.67-10.87-1.67h-18V254.17z"
                            />
                          </g>
                          <g>
                            <g>
                              <path
                                class="st0"
                                d="M48.21,48.77h8.24h13.82V67.6H48.21c-1.41,0-2.7,0.58-3.63,1.52c-0.94,0.94-1.52,2.23-1.52,3.63v8.06v202.45
			v8.06c0,1.41,0.58,2.69,1.52,3.63c0.94,0.94,2.23,1.52,3.63,1.52h173.4c1.41,0,2.69-0.58,3.63-1.52c0.94-0.94,1.52-2.23,1.52-3.63
			v-8.06V80.82v-8.06c0-1.41-0.58-2.69-1.52-3.63c-0.94-0.94-2.23-1.52-3.63-1.52h-22.06V48.77h13.82h8.24h5.16h18.83v15.76v8.23
			v8.06v202.45v8.06v8.23v15.76h-18.83h-5.16h-8.24H56.46h-8.24h-5.16H24.23v-15.76v-8.23v-8.06V80.82v-8.06v-8.23V48.77h18.83
			H48.21z"
                              />
                              <path
                                class="st1"
                                d="M84.84,31.77h4.72h21.24c0-10.99,10.79-19.9,24.11-19.9c13.31,0,24.11,8.91,24.11,19.9h21.24h4.72h9.86v8.14
			v14.91v21.85c0,4.48-4.44,8.14-9.86,8.14H84.84c-5.42,0-9.86-3.66-9.86-8.14V54.83V39.91v-8.14H84.84z"
                              />
                              <g>
                                <rect
                                  x="69.62"
                                  y="113.78"
                                  class="st0"
                                  width="130.58"
                                  height="19.74"
                                />
                                <rect
                                  x="69.62"
                                  y="160.41"
                                  class="st0"
                                  width="130.58"
                                  height="19.74"
                                />
                                <rect
                                  x="69.62"
                                  y="207.03"
                                  class="st0"
                                  width="130.58"
                                  height="19.74"
                                />
                                <rect
                                  x="69.62"
                                  y="253.66"
                                  class="st0"
                                  width="130.58"
                                  height="19.74"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-avatar :size="36" class="secondary lighten-1">
                <v-icon color="primary">person</v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title
                ><v-btn color="primary" text :to="`/profile`"
                  >profile</v-btn
                ></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-main class="content">
        <div class="pa-2">
          <router-view />
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
export default {
  name: "App",
  data: () => ({
    dialog: false,
    drawer: null,
    allNavItems: [
      {
        icon: "home",
        text: "Home",
        to: "/",
        role: [],
      },
      {
        icon: "person",
        text: "Customers",
        to: "/customers",
        role: [],
      },
      {
        icon: "task",
        text: "Projects",
        to: "/projects",
        role: [],
      },
      {
        icon: "calendar_today",
        text: "Calendar",
        to: "/calendar",
        role: [],
      },
      {
        icon: "upload",
        text: "Import",
        to: "/importer",
        role: [],
      },
    ],
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.$store.state;
    this.$store.watch(
      (state, getters) => getters.user,
      (newValue) => {
        this.navItems = this.allNavItems.filter((i) => {
          return i.role.includes(newValue.role) || !i.role.length;
        });
      }
    );
  },
  methods: {
    privateRoute() {
      return this.$route.meta.requiresAuth;
    },
    signout: async function () {
      try {
        await fb.auth.signOut();
        this.$router.push("/login");
      } catch (error) {
        alert(error);
      }
    },
    goHome() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      } else {
        return;
      }
    },
  },
};
</script>

<style>
.content {
  background: whitesmoke;
}
.st0 {
  fill: #f97b00;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f97b00;
}
svg {
  width: 150px;
}
</style>
