<template>
  <v-container grid-list-xl>
    <ProjectViewDialog :project="project" :visible="showProjectViewDialog" @close="showProjectViewDialog=false"></ProjectViewDialog>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-btn
          x-small
          style="margin-top: -3px"
          class="mr-2"
          :to="`/customers`"
          ><v-icon>chevron_left</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-list-item>
       <v-avatar color="primary">
          <v-icon dark>
            mdi-account-circle
          </v-icon>
        </v-avatar>
        <v-list-item-content class="ml-2">
          <v-list-item-title class="title">{{customer.firstName}} {{customer.lastName}}</v-list-item-title>
          <v-list-item-subtitle v-show="customer.company">@ {{customer.company || 'N/A'}}</v-list-item-subtitle>
          <small>{{formatAddress(customer.address)}}</small>
        </v-list-item-content>
      </v-list-item>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-card class="elevation-0">
          <v-card-title
            class="ma-0 primary white--text font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-1">Projects</span>
            <v-btn class="ml-4" color="white" small dark outlined :to="`/project/new/${customer.id}`"
              ><v-icon small class="mr-1">description</v-icon> create new</v-btn
            >
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="py-0" v-if="!loading">
                  <v-data-table
                    :headers="projectHeaders"
                    :items="projects"
                    :search="projectSearch"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="projectSearch"
                        outlined
                        label="Filter by project"
                        dense
                      ></v-text-field>
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title>Are you sure?</v-card-title>
                          <v-card-text>
                            Click on the delete button below to delete the
                            project permanently.
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="dialogDelete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              @click="confirmDelete"
                              :loading="dialogDeleteLoading"
                              :disabled="dialogDeleteLoading"
                              >Delete</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                    <template v-slot:item.createDate="{ item }">
                      <span v-if="item.createDate">
                      {{ new Date(item.createDate.seconds*1000) | moment("MMM D, YYYY") }}
                      </span>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-chip
                        :color="item.status === 'Not Started' ? 'warning' : item.status === 'In Progress' ? 'amber' : 'success'"
                        class="text-capitalize"
                        small
                      >
                        {{item.status}}
                      </v-chip>
                    </template>
                    <template v-slot:item.edit="{ item }">
                      <v-btn
                        color="primary"
                        text
                        outlined
                        small
                        class="mr-2"
                        @click.stop="project=item;showProjectViewDialog=true;"
                        >View</v-btn
                      >
                      <v-btn
                        color="primary"
                        text
                        outlined
                        small
                        class="mr-2"
                        :to="`/project/edit/${item.id}`"
                        >Edit</v-btn
                      >
                      <v-btn
                        color="error"
                        text
                        outlined
                        small
                        @click="deleteProject(item)"
                        ><v-icon small>delete</v-icon></v-btn
                      >
                    </template>
                    <template slot="no-data">
                      <span class="grey--text text--darken-3">
                        You have not added any projects yet.
                      </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-card class="elevation-0">
          <v-card-title
            class="ma-0 primary white--text font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-1">Events</span>
            <v-btn class="ml-4" color="white" small dark outlined :to="`/calendar`"
              ><v-icon small class="mr-1">calendar_today</v-icon> view calendar</v-btn
            >
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="py-0" v-if="!loading">
                  <v-data-table
                    :headers="eventHeaders"
                    :items="events"
                    :search="eventSearch"
                  >
                    <template v-slot:item.createDate="{ item }">
                      <span v-if="item.createDate">
                      {{ new Date(item.createDate.seconds*1000) | moment("MMM D, YYYY") }}
                      </span>
                    </template>
                    <template v-slot:item.start="{ item }">
                      <span v-if="item.start">
                      {{ new Date(item.start.seconds*1000) | moment("MM/DD/YYYY hh:mm A") }}
                      </span>
                    </template>
                    <template v-slot:item.end="{ item }">
                      <span v-if="item.end">
                      {{ new Date(item.end.seconds*1000) | moment("MM/DD/YYYY hh:mm A") }}
                      </span>
                    </template>
                     <template v-slot:item.notes="{ item }">
                      {{ !!item.notes ? 'Yes' : 'No' }}
                    </template>
                    <template v-slot:item.edit="{ item }">
                      <v-btn
                        color="error"
                        text
                        outlined
                        small
                        @click="deleteEvent(item)"
                        ><v-icon small>delete</v-icon></v-btn
                      >
                    </template>
                    <template slot="no-data">
                      <span class="grey--text text--darken-3">
                        You have not added any events yet.
                      </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
import { mapGetters } from "vuex";
import { formatAddress } from "@/helpers";
import ProjectViewDialog from "@/components/ProjectViewDialog";

export default {
  name: "customer-profile",
  components: { ProjectViewDialog },
  data() {
    return {
      dialogDeleteLoading: false,
      dialogDelete: false,
      showProjectViewDialog: false,
      project: {},
      projectSearch: "",
      loading: true,
      snack: false,
      snackColor: "",
      snackText: "",
      projectHeaders: [
        {
          text: "Created",
          value: "createDate",
          width: 120,
        },
        {
          text: "Project Name",
          value: "name"
        },
        {
          text: "Zones",
          value: "zones"
        },
        {
          text: "Sq. Ft.",
          value: "squareFootage"
        },
        {
          text: "Cost",
          value: "cost"
        },
        {
          text: "Quote",
          value: "quote"
        },
        {
          text: "Status",
          value: "status"
        },
        { text: "",
          value: "edit",
          align: "end",
          width: "220"
        },
      ],
      projects: [],
      events: [],
      customer: {},
      eventSearch: "",
      eventHeaders: [
        {
          text: "Created",
          value: "createDate",
          width: 120,
        },
        {
          text: "Title",
          value: "title"
        },
        {
          text: "Start Time",
          value: "start"
        },
        {
          text: "End Time",
          value: "end"
        },
        {
          text: "Location",
          value: "location"
        },
        {
          text: "Notes",
          value: "notes"
        },
        { text: "",
          value: "edit",
          align: "end",
          width: "220"
        },
      ],
    };
  },
  async created() {
    await this.getCustomer();
    await this.getProjects();
    await this.getEvents();
    this.loading = false;
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    formatAddress: formatAddress,
    async getCustomer() {
      const customersRes = await fb.db
        .collection("customers")
        .doc(this.$route.params.customerId);
      const customerDoc = await customersRes.get();
      const customer = {
        ...customerDoc.data(),
        id: customerDoc.id
      }
      this.customer = customer;
    },
    async getProjects() {
      const projectsRes = await fb.db
        .collection("projects")
        .where("createdBy.uid", "==", this.user.uid)
        .where("customerId", "==", this.$route.params.customerId)
        .get();
      const projects = [];
      projectsRes.forEach((q) => {
        const data = q.data();
        projects.push({
          ...data,
          id: q.id,
          customer: this.customer
        });
      });
      this.projects = projects;
    },
    async getEvents() {
      const eventsRes = await fb.db
        .collection("events")
        .where("customerId", "==", this.$route.params.customerId)
        .get();
      const events = [];
      eventsRes.forEach((q) => {
        const data = q.data();
        events.push({
          ...data,
          id: q.id
        });
      });
      console.log(events)
      this.events = events;
    },
    deleteProject(project) {
      this.currentProject = {};
      this.currentProject = { ...project };
      this.dialogDelete = true;
    },
    async confirmDelete() {
      try {
        this.dialogDeleteLoading = true;
        const projectId = this.currentProject.id;
        await fb.db.doc(`projects/${projectId}`).delete();
        await this.getProjects();
        this.dialogDelete = false;
        this.currentProject = {};
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully deleted project.";
      } catch (error) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error deleting project.";
      }
      this.dialogDeleteLoading = false;
    },
    async deleteEvent (item) {
      if (confirm("Are you sure?") == true) {
        await fb.db.collection("events").doc(item.id).delete();
        this.getEvents();
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully deleted event.";
      }
    }
  },
};
</script>