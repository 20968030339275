<template>
  <v-main class="login-bg">
    <v-container fluid fill-height grid-list-md>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8>
          <v-card class="elevation-12 signup-card">
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12>
                  <div>
                    <h2 class="text-center font-weight-regular pt-0 mt-0">
                      Terms & Conditions
                    </h2>
                  </div>
                  <br />
                  <strong>1. Acceptance of Terms</strong>
                  <p>By using the HVAC Manager PRO web application ("the App"), you agree to comply with and be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use the App.</p>

                  <strong>2. Privacy Policy</strong>
                  <p>Your use of the App is also governed by our <a href="/privacy-policy">Privacy Policy</a>, which is incorporated by reference into these terms and conditions.</p>

                  <strong>3. License</strong>
                  <p>Subject to these terms, we grant you a limited, non-exclusive, non-transferable license to access and use the App for its intended purpose.</p>

                  <strong>4. User Responsibilities</strong>
                  <p>When using the App, you agree to:</p>
                  <ul>
                      <li>Provide accurate and complete information during registration.</li>
                      <li>Keep your login credentials secure and not share them with others.</li>
                      <li>Comply with all applicable laws and regulations.</li>
                      <li>Not engage in any illegal or harmful activities while using the App.</li>
                  </ul>
                  <br/>
                  <strong>5. Termination</strong>
                  <p>We reserve the right to terminate or suspend your access to the App at our discretion, without notice, if you violate these terms or engage in any prohibited activities.</p>

                  <strong>6. Disclaimer of Warranty</strong>
                  <p>The App is provided "as is" without warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>

                  <strong>7. Limitation of Liability</strong>
                  <p>We shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, arising from your use of the App.</p>

                  <strong>8. Changes to Terms</strong>
                  <p>We may modify these terms and conditions at any time, and it is your responsibility to review them periodically. Your continued use of the App after any changes constitutes your acceptance of the revised terms.</p>

                  <strong>9. Contact Information</strong>
                  <p>If you have any questions or concerns about these terms and conditions, please contact us at <span class="orange--text text--darken-2">info [at] hvacmanagerpro [dot] com</span>.</p>

                  <p>This document was last updated on September 5, 2023.</p>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "terms-and-conditions",
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.signup-card {
  padding: 15px;
}
.login-bg {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("~@/assets/img/bg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}
</style>
