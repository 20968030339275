<template>
  <v-container grid-list-lg>
    <v-layout wrap>
      <v-col xs12>
        <v-card>
          <v-card-title primary-title> Verification </v-card-title>
          <v-card-text class="pt-4">
            <p>
              Please check your email for a verification link. Once you have
              verified your email.
              <br />** Come back to this page and you will be automatically
              redirected to the home page.
            </p>
            <p>
              Look for an email from
              <strong>noreply@hvacmanagerpro.com</strong>.
            </p>
            <p>
              Resend verification email <br />
              <v-btn
                color="primary"
                small
                :disabled="verificationEmailSent"
                @click="resendVerificationEmail"
                >Resend Verification email</v-btn
              >
            </p>
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
              {{ snackText }}
              <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snack = false">
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
export default {
  data() {
    return {
      checkForVerifiedInterval: null,
      verificationEmailSent: false,
      snack: false,
      snackColor: "",
      snackText: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  created() {
    this.checkForVerifiedInterval = setInterval(() => {
      console.log("checking if verified");
      fb.auth.currentUser.reload().then(() => {
        if (fb.auth.currentUser.emailVerified) {
          console.log("verified!!");
          clearInterval(this.checkForVerifiedInterval);
          this.$router.push("/");
        }
      });
    }, 2000);
  },
  methods: {
    async resendVerificationEmail() {
      await fb.auth.currentUser.sendEmailVerification();
      this.verificationEmailSent = true;
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Verification email sent.";
    },
  },
};
</script>
