<template>
  <v-container grid-list-xl>
    <ProjectViewDialog :project="project" :visible="showProjectViewDialog" @close="showProjectViewDialog=false"></ProjectViewDialog>
    <CustomerViewDialog :customer="customer" :visible="showCustomerViewDialog" @close="showCustomerViewDialog=false"></CustomerViewDialog>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-card class="elevation-0">
          <v-card-title
            class="ma-0 primary white--text font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-1">Projects</span>
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="py-0" v-if="!loading">
                  <v-data-table
                    :headers="headers"
                    :items="projects"
                    :search="search"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        outlined
                        label="Filter by project"
                        dense
                      ></v-text-field>
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title>Are you sure?</v-card-title>
                          <v-card-text>
                            Click on the delete button below to delete the
                            project permanently.
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="dialogDelete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              @click="confirmDelete"
                              :loading="dialogDeleteLoading"
                              :disabled="dialogDeleteLoading"
                              >Delete</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                    <template v-slot:item.createDate="{ item }">
                      <span v-if="item.createDate">
                      {{ new Date(item.createDate.seconds*1000) | moment("MMMM D, YYYY") }}
                      </span>
                    </template>
                    <template v-slot:item.customerName="{ item }">
                      <button @click.stop="customer=item.customer;showCustomerViewDialog=true;" class="orange--text text--darken-2"
                      >
                        {{item.customerName}}
                      </button>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-chip
                        :color="item.status === 'Not Started' ? 'warning' : item.status === 'In Progress' ? 'amber' : 'success'"
                        class="text-capitalize"
                        small
                      >
                        {{item.status}}
                      </v-chip>
                    </template>
                    <template v-slot:item.edit="{ item }">
                      <v-btn
                        color="primary"
                        text
                        outlined
                        small
                        class="mr-2"
                        @click.stop="project=item;showProjectViewDialog=true;"
                        >View</v-btn
                      >
                      <v-btn
                        color="primary"
                        text
                        outlined
                        small
                        class="mr-2"
                        :to="`/project/edit/${item.id}?projectTab=true`"
                        >Edit</v-btn
                      >
                      <v-btn
                        color="error"
                        text
                        outlined
                        small
                        @click="deleteProject(item)"
                        ><v-icon small>delete</v-icon></v-btn
                      >
                    </template>
                    <template slot="no-data">
                      <span class="grey--text text--darken-3">
                        You have not added any projects yet.
                      </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
import { mapGetters } from "vuex";
import ProjectViewDialog from "@/components/ProjectViewDialog";
import CustomerViewDialog from "@/components/CustomerViewDialog";

export default {
  name: "Projects",
  components: { ProjectViewDialog, CustomerViewDialog },
  data() {
    return {
      dialogDeleteLoading: false,
      dialogDelete: false,
      search: "",
      loading: true,
      snack: false,
      snackColor: "",
      snackText: "",
      showProjectViewDialog: false,
      project: {},
      showCustomerViewDialog: false,
      customer: {},
      headers: [
        {
          text: "Created",
          value: "createDate",
          width: 120,
        },
        {
          text: "Project Name",
          value: "name"
        },
        {
          text: "Customer",
          value: "customerName"
        },
        {
          text: "Zones",
          value: "zones"
        },
        {
          text: "Sq. Ft.",
          value: "squareFootage"
        },
        {
          text: "Cost",
          value: "cost"
        },
        {
          text: "Quote",
          value: "quote"
        },
        {
          text: "Status",
          value: "status"
        },
        { text: "",
          value: "edit",
          align: "end",
          width: "220"
        },
      ],
      projects: [],
    };
  },
  async created() {
    this.getProjects();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getProjects() {
      const customersRes = await fb.db
        .collection("customers")
        .where("createdBy.uid", "==", this.user.uid)
        .get();
      const customers = [];
      customersRes.forEach((q) => {
        customers.push({
          ...q.data(),
          id: q.id,
        });
      });

      const projectsRes = await fb.db
        .collection("projects")
        .where("createdBy.uid", "==", this.user.uid)
        .get();
      const projects = [];
      projectsRes.forEach((q) => {
        const data = q.data();
        const customer = customers.find((c) => {return c.id == data.customerId});
        projects.push({
          ...data,
          id: q.id,
          customerName: customer.company || `${customer.firstName} ${customer.lastName}`,
          customer: customer
        });
      });
      this.projects = projects;
      this.loading = false;
    },
    deleteProject(project) {
      this.currentProject = {};
      this.currentProject = { ...project };
      this.dialogDelete = true;
    },
    async confirmDelete() {
      try {
        this.dialogDeleteLoading = true;
        console.log(this.currentProject.id)
        const projectId = this.currentProject.id;
        await fb.db.doc(`projects/${projectId}`).delete();
        await this.getProjects();
        this.dialogDelete = false;
        this.currentProject = {};
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully deleted project.";
      } catch (error) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error deleting project.";
      }
      this.dialogDeleteLoading = false;
    },
  },
};
</script>