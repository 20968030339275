const formatAddress = (address) => {
  if (!address) {
    return '';
  }
  let addressFormatted = '';
  if (address.street) {
    addressFormatted += `${address.street}, `;
  }
  if (address.street2) {
    addressFormatted += `${address.street2}, `;
  }
  if (address.city) {
    addressFormatted += `${address.city}, `;
  }
  if (address.state) {
    addressFormatted += `${address.state}`;
  }
  if (address.zip) {
    addressFormatted += `, ${address.zip}`;
  }
  if (address.country) {
    addressFormatted += `, ${address.country}`;
  }
  addressFormatted = (addressFormatted.length && addressFormatted[0] == ',') ? addressFormatted.slice(1) : addressFormatted;
  return addressFormatted;
}

export {
  formatAddress
}