import Vue from "vue";
import Router from "vue-router";
import fb from '@/main';
import store from "./store";

import Home from "@/views/Home.vue";
import Profile from "./views/Profile.vue";
import Login from "./views/Login.vue";
import Signup from "./views/Signup.vue";
import PrivacyPolicy from "./views/PrivacyPolicy.vue"
import TermsAndConditions from "./views/TermsAndConditions.vue"
import Verification from "./views/Verification.vue";
import Customer from "./views/Customer.vue";
import Customers from "./views/Customers.vue";
import CustomerProfile from "./views/CustomerProfile.vue";
import Project from "./views/Project.vue";
import Projects from "./views/Projects.vue";
import Calendar from "./views/Calendar.vue";
import Importer from "./views/Importer.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: { requiresAuth: true }
    },
    {
      path: "/verification",
      name: "verification",
      component: Verification,
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/signup",
      name: "signup",
      component: Signup
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: PrivacyPolicy
    },
    {
      path: "/terms-and-conditions",
      name: "terms-and-conditions",
      component: TermsAndConditions
    },
    {
      path: "/customers",
      name: "customers",
      component: Customers,
      meta: { requiresAuth: true }
    },
    {
      path: "/customer",
      name: "customer",
      component: Customer,
      meta: { requiresAuth: true }
    },
    {
      path: "/customer/new",
      name: "new-customer",
      component: Customer,
      meta: { requiresAuth: true }
    },
    {
      path: "/customer/edit/:customerId",
      name: "edit-customer",
      component: Customer,
      meta: { requiresAuth: true }
    },
    {
      path: "/customer/profile/:customerId",
      name: "view-customer",
      component: CustomerProfile,
      meta: { requiresAuth: true }
    },
    {
      path: "/projects",
      name: "projects",
      component: Projects,
      meta: { requiresAuth: true }
    },
    {
      path: "/project/new/:customerId",
      name: "new-project",
      component: Project,
      meta: { requiresAuth: true }
    },
    {
      path: "/project/edit/:projectId",
      name: "edit-project",
      component: Project,
      meta: { requiresAuth: true }
    },
    {
      path: "/calendar",
      name: "calendar",
      component: Calendar,
      meta: { requiresAuth: true }
    },
    {
      path: "/importer",
      name: "importer",
      component: Importer,
      meta: { requiresAuth: true }
    }
  ]
});
router.beforeEach(async (to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NO logged userd
    if (!fb.auth.currentUser) {
      next({
        path: '/login'
      });
    } else {
      if(store.getters['user']){
        // don't need to fetch user, already cached
        if(!fb.auth.currentUser.emailVerified){
          next({
            path: '/verification'
          });
        } else {
          next()
        }
      } else {
        if(!fb.auth.currentUser.emailVerified){
          next({
            path: '/verification'
          });
        } else {
          await store.dispatch('getUser')
          next();
        }
      }
    }
  } else {
    // Proceed to route
    next()
  }
});
export default router;
