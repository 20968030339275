<template>
  <v-main class="login-bg">
    <v-container fluid fill-height grid-list-md>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8>
          <v-card class="elevation-12 signup-card">
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12>
                  <div>
                    <h2 class="text-center font-weight-regular pt-0 mt-0">
                      Privacy Policy
                    </h2>
                  </div>
                  <br />
                  <p>Effective Date: September 5, 2023</p>

                  <p>This Privacy Policy describes how HVAC Manager PRO ("we," "our," or "us") collects, uses, and discloses information when you use our web application ("the App"). By accessing or using the App, you consent to the practices described in this policy.</p>

                  <strong>Information We Collect</strong>
                  <p>We may collect the following types of information:</p>
                  <ul>
                      <li>Personal Information: We may collect personal information, such as your name and email address when you register for an account or contact us.</li>
                      <li>Usage Information: We may collect information about how you interact with the App, including your IP address, browser type, and pages visited.</li>
                  </ul>
                  <br/>
                  <strong>How We Use Your Information</strong>
                  <p>We may use the information we collect for various purposes, including:</p>
                  <ul>
                      <li>Providing and improving the App.</li>
                      <li>Sending you updates and notifications.</li>
                      <li>Responding to your requests and inquiries.</li>
                      <li>Enforcing our terms and policies.</li>
                  </ul>
                  <br/>
                  <strong>Disclosure of Your Information</strong>
                  <p>We may disclose your information to:</p>
                  <ul>
                      <li>Service Providers: We may share your information with third-party service providers who assist us in operating the App and providing services to you.</li>
                      <li>Legal Requirements: We may disclose your information to comply with applicable laws, regulations, or legal processes.</li>
                  </ul>
                  <br/>
                  <strong>Security</strong>
                  <p>We take reasonable measures to protect your information from unauthorized access or disclosure. However, no data transmission over the internet can be guaranteed to be 100% secure.</p>

                  <strong>Your Choices</strong>
                  <p>You may have certain rights regarding your information, including the right to access, correct, or delete your personal information. To exercise these rights, please contact us at <span class="orange--text text--darken-2">info [at] hvacmanagerpro [dot] com</span>.</p>

                  <strong>Changes to this Privacy Policy</strong>
                  <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the updated policy on this page.</p>

                  <strong>Contact Us</strong>
                  <p>If you have any questions or concerns about this Privacy Policy, please contact us at <span class="orange--text text--darken-2">info [at] hvacmanagerpro [dot] com</span>.</p>

                  <p>This document was last updated on September 5, 2023.</p>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "privacy-policy",
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.signup-card {
  padding: 15px;
}
.login-bg {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("~@/assets/img/bg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}
</style>
