var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xl":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12"}},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticClass:"ma-0 primary white--text font-weight-black headline",staticStyle:{"overflow":"hidden"}},[_c('span',{staticClass:"ml-1"},[_vm._v("Customers")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"white","small":"","dark":"","outlined":"","to":"/customer/new"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("description")]),_vm._v(" create new")],1)],1),_c('v-card-text',{staticClass:"pt-4 px-1"},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(!_vm.loading)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customers,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"outlined":"","label":"Filter by customer","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v("Are you sure?")]),_c('v-card-text',[_vm._v(" Click on the delete button below to delete the customer permanently. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.dialogDeleteLoading,"disabled":_vm.dialogDeleteLoading},on:{"click":_vm.confirmDelete}},[_vm._v("Delete")])],1)],1)],1)]},proxy:true},{key:"item.createDate",fn:function(ref){
var item = ref.item;
return [(item.createDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(item.createDate.seconds*1000),"MMM D, YYYY"))+" ")]):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.lastProjectDate",fn:function(ref){
var item = ref.item;
return [(item.lastProjectDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(item.lastProjectDate.seconds*1000),"MMM D, YYYY"))+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":item.status === 'Lead' ? 'amber' : 'success',"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","text":"","outlined":"","small":"","to":("/customer/profile/" + (item.id))}},[_vm._v("Profile")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","text":"","outlined":"","small":"","to":("/customer/edit/" + (item.id))}},[_vm._v("edit")]),_c('v-btn',{attrs:{"color":"error","text":"","outlined":"","small":""},on:{"click":function($event){return _vm.deleteCustomer(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,false,2097628565)},[_c('template',{slot:"no-data"},[_c('span',{staticClass:"grey--text text--darken-3"},[_vm._v(" You have not added any customers yet. Click the "),_c('strong',[_vm._v("\"Create New\"")]),_vm._v(" button above. ")])])],2)],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }