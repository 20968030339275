<template>
  <v-container grid-list-xl>
    <v-row>
      <v-col lg="12" cols="sm" class="pb-2">
        <v-card>
          <v-card-title
            class="ma-0 primary white--text font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-1">Import Customers</span>
          </v-card-title>
          <v-card-title>
            <v-file-input
              label="File input"
              v-model="file"
              accept=".csv"
              persistent-hint
              hint="Upload a .csv file to import your existing customer list. Please use our sample .csv as a template."
            ></v-file-input>
            <v-btn
              color="primary"
              class="ml-2"
              @click="upload"
              :loading="uploading"
              :disabled="uploading || !file"
              ><v-icon small left>upload</v-icon> Upload</v-btn
            >
          </v-card-title>
          <vue-json-to-csv :json-data="csvSample" :csv-title="'customer-import-sample-template-'+new Date().getTime()">
            <v-btn color="primary" small class="ml-12">
              <v-icon small left>download</v-icon> Sample CSV
            </v-btn>
          </vue-json-to-csv>
          <v-card-text class="mt-0 pt-0">
            <p class="pt-4" v-if="uploading">
              <strong>Uploading Data</strong><br />
              <v-progress-linear
                indeterminate
                color="primary"
                class="mb-2 mt-2"
              ></v-progress-linear>
              <v-icon color="orange" small>warning</v-icon> Please do not close
              this browser window.
            </p>
            <p class="pt-4" v-if="success">
              <v-icon color="success" small>check_circle</v-icon> Successfully
              added {{ recordCount }} records.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
import { mapGetters } from "vuex";
import { differenceBy } from "lodash";
import csv from "csvtojson";
import VueJsonToCsv from 'vue-json-to-csv';


export default {
  name: "Importer",
  components: {
    VueJsonToCsv,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => {
    return {
      file: null,
      loading: false,
      uploading: false,
      success: false,
      recordCount: 0,
      snack: false,
      snackColor: "",
      snackText: "",
      csvSample: [
        {
          'company':"",
          'firstName':"",
          'lastName':"",
          'phone':"",
          'email':"",
          'notes':"",
          'address street1':"",
          'address street2':"",
          'address city':"",
          'address state':"",
          'address zip':"",
          'address country':"",
        }
      ]
    }
  },
  async created() {
    this.loading = true;
    await this.getCustomers();
    this.loading = false;
  },
  mounted: async function() {
  },
  methods: {
    async getCustomers() {
      const customersRes = await fb.db
        .collection("customers")
        .where("createdBy.uid", "==", this.user.uid)
        .get();
      const customers = [];
      customersRes.forEach((q) => {
        customers.push({
          ...q.data(),
          id: q.id,
        });
      });
      this.customers = customers;
    },
    async upload() {
      try {
        if (this.file) {
          this.uploading = true;
          this.success = false;

          const reader = new FileReader();
          reader.onload = (e) => {
            const csvString = e.target.result;
            csv().fromString(csvString).then(async(result) => {
              if (!result.length) {
                alert('Please try again. No data was found.')
                return;
              }
              const differentColumnHeaders = differenceBy(Object.keys(result[0]),Object.keys(this.csvSample[0]));
              if (differentColumnHeaders.length) {
                alert(`Please try again. The following column headers are not supported: ${differentColumnHeaders.join(', ')}`)
                return
              }
              // make sure we don't process already added customers
              const unqiueKey = function(customer) {
                let key = "";
                key += (customer.lastName || "").replace(/\W/g, '');
                key += (customer.email || "").replace(/\W/g, '');
                key += (customer.zip || "").replace(/\W/g, '');
                return key;
              }
              const existingCustomerKeys = this.customers.map((c) => {return unqiueKey(c)});
              const toBeInserted = [];
              const failedCheck = [];
              for (const r of result) {
                if (!existingCustomerKeys.includes(unqiueKey(r))) {
                  const obj = {
                    createDate: fb.timestamp,
                    createdBy: this.user,
                    imported: true,
                    status: "Customer",
                    address: {},
                  };
                  ['company','firstName','lastName','phone'].forEach((key) => {
                    if (!r[key] && !failedCheck.includes(key)) {
                      failedCheck.push(key)
                    }
                    obj[key] = r[key];
                  });
                  ['email', 'notes'].forEach((key) => {
                    obj[key] = r[key];
                  });
                  ['address street1', 'address street2', 'address city', 'address state', 'address zip', 'address country'].forEach((key) => {
                    obj.address[key.replace('address ', '')] = r[key];
                  });
                  toBeInserted.push(obj);
                  existingCustomerKeys.push(unqiueKey(r));
                }
              }
              if (failedCheck.length) {
                alert(`Please try again. The following customer fields are required: ${failedCheck.join(', ')}`)
              } else {
                for (const obj of toBeInserted) {
                  await fb.db.collection("customers").add(obj);
                }
                this.snack = true;
                this.snackColor = "success";
                this.snackText = "Successfully imported customers.";
              }
            });
          };
          reader.readAsText(this.file);
        }
        this.uploading = false;
        this.file = null;
      } catch (error) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error importing customers.";
      }
    }
  }
};
</script>