<template>
  <v-container grid-list-xl>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-card class="elevation-0">
          <v-card-title
            class="ma-0 primary white--text font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-1">Customers</span>
            <v-btn class="ml-4" color="white" small dark outlined to="/customer/new"
              ><v-icon small class="mr-1">description</v-icon> create new</v-btn
            >
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="py-0" v-if="!loading">
                  <v-data-table
                    :headers="headers"
                    :items="customers"
                    :search="search"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        outlined
                        label="Filter by customer"
                        dense
                      ></v-text-field>
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title>Are you sure?</v-card-title>
                          <v-card-text>
                            Click on the delete button below to delete the
                            customer permanently.
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="dialogDelete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              @click="confirmDelete"
                              :loading="dialogDeleteLoading"
                              :disabled="dialogDeleteLoading"
                              >Delete</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                    <template v-slot:item.createDate="{ item }">
                      <span v-if="item.createDate">
                      {{ new Date(item.createDate.seconds*1000) | moment("MMM D, YYYY") }}
                      </span>
                    </template>
                    <template v-slot:item.name="{ item }">
                      {{ item.firstName }} {{ item.lastName }}
                    </template>
                    <template v-slot:item.lastProjectDate="{ item }">
                      <span v-if="item.lastProjectDate">
                      {{ new Date(item.lastProjectDate.seconds*1000) | moment("MMM D, YYYY") }}
                      </span>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-chip
                        :color="item.status === 'Lead' ? 'amber' : 'success'"
                        class="text-capitalize"
                        small
                      >
                        {{item.status}}
                      </v-chip>
                    </template>
                    <template v-slot:item.edit="{ item }">
                      <v-btn
                        color="primary"
                        text
                        outlined
                        small
                        class="mr-2"
                        :to="`/customer/profile/${item.id}`"
                        >Profile</v-btn
                      >
                      <v-btn
                        color="primary"
                        text
                        outlined
                        small
                        class="mr-2"
                        :to="`/customer/edit/${item.id}`"
                        >edit</v-btn
                      >
                      <v-btn
                        color="error"
                        text
                        outlined
                        small
                        @click="deleteCustomer(item)"
                        ><v-icon small>delete</v-icon></v-btn
                      >
                    </template>
                    <template slot="no-data">
                      <span class="grey--text text--darken-3">
                        You have not added any customers yet. Click the
                        <strong>"Create New"</strong>
                        button above.
                      </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
import { mapGetters } from "vuex";

export default {
  name: "Customers",
  data() {
    return {
      dialogDeleteLoading: false,
      dialogDelete: false,
      search: "",
      loading: true,
      dataExists: false,
      snack: false,
      snackColor: "",
      snackText: "",
      headers: [
        {
          text: "Created",
          value: "createDate",
        },
        {
          text: "Company",
          value: "company",
        },
        {
          text: "Name",
          value: "customerName"
        },
        {
          text: "Phone",
          value: "phone"
        },
        {
          text: "Projects",
          value: "totalProjects"
        },
        {
          text: "Last Project",
          value: "lastProjectDate"
        },
        {
          text: "Status",
          value: "status"
        },
        { text: "",
          value: "edit",
          align: "end",
          width: "240"
        },
      ],
      customers: [],
    };
  },
  async created() {
    this.getCustomers();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getCustomers() {
      const projectsRes = await fb.db
        .collection("projects")
        .where("createdBy.uid", "==", this.user.uid)
        .get();
      const projects = [];
      projectsRes.forEach((q) => {
        projects.push({
          ...q.data(),
          id: q.id,
        });
      });

      const customersRes = await fb.db
        .collection("customers")
        .where("createdBy.uid", "==", this.user.uid)
        .get();
      const customers = [];
      customersRes.forEach((q) => {
        const lastProject = projects.find((p) => {return p.customerId == q.id});
        const allProjects = projects.filter((p) => {return p.customerId == q.id});
        const data = q.data();
        customers.push({
          ...data,
          id: q.id,
          customerName: `${data.firstName} ${data.lastName}`,
          lastProjectDate: lastProject ? lastProject.createDate : '',
          totalProjects: allProjects.length
        });
      });
      this.customers = customers;
      this.loading = false;
    },
    deleteCustomer(customer) {
      this.currentCustomer = {};
      this.currentCustomer = { ...customer };
      this.dialogDelete = true;
    },
    async confirmDelete() {
      try {
        this.dialogDeleteLoading = true;
        const customerId = this.currentCustomer.id;
        await fb.db.doc(`customers/${customerId}`).delete();
        await this.getCustomers();
        this.dialogDelete = false;
        this.currentCustomer = {};
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully deleted customer.";
      } catch (error) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error deleting customer.";
      }
      this.dialogDeleteLoading = false;
    },
  },
};
</script>